import React from 'react';
import { Link, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import LightBox from 'react-images';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeaderSection from '../components/sections/HeaderSection';
import { CallbackOnEnter } from '../lib/events';
import JannikWeichertImage from '../images/about-us/jannik-weichert.jpg';
import JanPapenbrockImage from '../images/about-us/jan-papenbrock.png';
import MapIcon from '../images/icons/blue/map.svg';
import TimeIcon from '../images/icons/blue/time.svg';
import PcIcon from '../images/icons/blue/pc.svg';
import GlobeIcon from '../images/icons/blue/globe.svg';
import PathIcon from '../images/icons/blue/path.svg';
import BusIcon from '../images/icons/blue/Bus.svg';
import ToolOverview from '../components/pages/career/ToolOverview';
import JoinButton from '../components/misc/JoinButton';


class CareerPage extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  static isVacancy(edge) {
    const path = edge.node.frontmatter.path;
    const result = path != null && path.startsWith('/career/vacancies/');
    console.log('is ' + path + ' a vacany? => ' + result);
    console.log(JSON.stringify(edge));
    return result;
  }

  constructor(props) {
    super(props);

    this.state = {
      officeImageMuensterOpen: false,
      officeImageKoelnOpen: false,
    };
  }

  render() {
    const { data } = this.props;
    const { officeImageMuensterOpen, officeImageKoelnOpen } = this.state;

    return (
      <Layout>
        <SEO title="Karriere" keywords={[]} />
        <HeaderSection>
          <h1>
            Let’s create great products.
            <br />
            Join hochzehn.
          </h1>
          <p className="w-full md:w-2/3 mt-8">
            Wir lieben es, mit motivierten, inspirierten Menschen zusammen zu arbeiten und Visionen zu verwirklichen. Wir sind begeistert von Technologien und davon, mit ihrem Einsatz Neues zu erschaffen.
          </p>
        </HeaderSection>

        {/* no jobs right now, see below for jobs markdown */}
        <div className="flex flex-col">
          <div className="flex flex-row justify-center">
            <div className="flex flex-col">
              <p>Wir suchen momentan leider keine weiteren Verstärkungen, sorry.</p>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col"> */}
        {/*   <div className="flex flex-row justify-center"> */}
        {/*     <div className="flex flex-col"> */}
        {/*       <h3>Du auch? Wir freuen uns auf dich als Teil unseres Teams.</h3> */}
        {/*     </div> */}
        {/*   </div> */}
        {/*   <div className="flex flex-col mt-12"> */}
        {/*     {data.allMarkdownRemark.edges.filter(edge => CareerPage.isVacancy(edge)).map(vacancy => ( */}
        {/*       <Vacancy key={vacancy.node.id} vacancy={vacancy} /> */}
        {/*     ))} */}
        {/*   </div> */}
        {/*   <div className="flex flex-row justify-center"> */}
        {/*     <JoinButton label="Jetzt bewerben" /> */}
        {/*   </div> */}
        {/* </div> */}

        <div className="flex items-center flex-col">
          <h2>Benefits</h2>
          <div className="flex flex-row flex-wrap mt-8">
            <div className="flex flex-col items-center w-1/2 md:w-1/3 p-4 text-center">
              <img alt="location" src={MapIcon} />
              <span className="mt-2">Zentrale Lage</span>
            </div>
            <div className="flex flex-col items-center w-1/2 md:w-1/3 p-4 text-center">
              <img alt="time" src={TimeIcon} />
              <span className="mt-2">Flexible Arbeitszeiten</span>
            </div>
            <div className="flex flex-col items-center w-1/2 md:w-1/3 p-4 text-center">
              <img alt="globe" src={GlobeIcon} />
              <span className="mt-2">Home Office</span>
            </div>
            <div className="flex flex-col items-center w-1/2 md:w-1/3 p-4 text-center">
              <img alt="computer" src={PcIcon} />
              <span className="mt-2">State-Of-The-Art-Technologien</span>
            </div>
            <div className="flex flex-col items-center w-1/2 md:w-1/3 p-4 text-center">
              <img alt="bus" src={BusIcon} />
              <span className="mt-2">Jobticket</span>
            </div>
            <div className="flex flex-col items-center w-1/2 md:w-1/3 p-4 text-center">
              <img alt="path" src={PathIcon} />
              <span className="mt-2">Weiterbildung</span>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <JoinButton label="Klingt super" />
          </div>
        </div>

        <div className="flex flex-col bg-grey-lighter mt-12 p-12">
          <div className="flex flex-row flex-wrap">
            <div className="flex flex-col w-full justify-center">
              <h2 className="my-4">Unser Büro</h2>
              <p className="mt-3 max-w-lg">
                Bei uns kannst du entscheiden an welchem Standort du arbeiten möchtest. Im schönen Münster mit Blick auf den Innenhafen oder im wunderbaren Köln in direkter Domnähe.
              </p>
            </div>
          </div>

          <div className="flex flex-row flex-wrap mt-12">
            <div className="flex flex-col w-full md:w-1/2 md:pr-4 my-4">
              <div className="flex flex-row items-center">
                <h3>Münster</h3>
                <a className="animate-find-us" href="https://goo.gl/maps/ToB6WrPh6p72" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon className="ml-2" icon={faLocationArrow} />
                </a>
              </div>
              <p className="mt-3">
                Mit einem tollen Blick auf den Hafen ist unser Büro in Münster ausgestattet - und dank Südausrichtung scheint die Sonne oft angenehm herein. Als Mieter in den Räumen des Digital Hub münsterLAND knüpfen wir enge Kontakte zur digitalen und Start-Up-Szene Münsters.
              </p>
              <div className="flex flex-row mt-12">
                <div
                  className="flex flex-col w-full"
                  onClick={() => this.setState({ officeImageMuensterOpen: true })}
                  onKeyDown={CallbackOnEnter(() => this.setState({ officeImageMuensterOpen: true }))}
                  tabIndex={0}
                  role="link"
                >
                  <Img
                    fluid={data.officeImageMuenster.childImageSharp.fluid}
                    className="cursor-pointer"
                  />
                </div>
                <LightBox
                  images={[data.officeImageMuenster.childImageSharp.fluid]}
                  alt="Münster Office"
                  isOpen={officeImageMuensterOpen}
                  onClose={() => this.setState({ officeImageMuensterOpen: false })}
                  backdropClosesModal
                />
              </div>
            </div>

            <div className="flex flex-col w-full md:w-1/2 md:pl-4 my-4">
              <div className="flex flex-row items-center">
                <h3>Köln</h3>
                <a href="https://goo.gl/maps/1AJ5yt1S9pE2" className="animate-find-us" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon className="ml-2" icon={faLocationArrow} />
                </a>
              </div>
              <p className="mt-3">
                Mitten im Herzen von Köln haben wir unser Büro aufgeschlagen. In unserer Bürogemeinschaft haben wir spannende Nachbarn, die Lage direkt am Dom und Hauptbahnhof erlaubt eine schnelle Anbindung und - noch wichtiger - eine grandiose Auswahl verschiedenster Mittags-Mahlzeiten.
              </p>
              <div className="flex flex-row mt-12">
                <div
                  className="flex flex-col w-full"
                  onClick={() => this.setState({ officeImageKoelnOpen: true })}
                  onKeyDown={CallbackOnEnter(() => this.setState({ officeImageKoelnOpen: true }))}
                  tabIndex={0}
                  role="link"
                >
                  <Img
                    fluid={data.officeImageKoeln.childImageSharp.fluid}
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <LightBox
                images={[data.officeImageKoeln.childImageSharp.fluid]}
                alt="Köln Office"
                isOpen={officeImageKoelnOpen}
                onClose={() => this.setState({ officeImageKoelnOpen: false })}
                backdropClosesModal
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-center">
              <h2>Lerne deine Kollegen kennen</h2>
            </div>
            <div className="flex flex-row mt-6">
              <Link className="button button-animate button-outlined" to="/about-us">
                Über uns
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
            <div className="flex flex-row max-w-sm mt-6">
              <div className="w-1/2 p-4">
                <Link className="inline-block float-left" to="/about-us">
                  <img alt="Bild von Jannik Weichert" src={JannikWeichertImage} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <h2>Tools mit denen du arbeiten wirst</h2>
          <ToolOverview />
          <div className="flex flex-row justify-center">
            <JoinButton label="Ich bin dabei!" />
          </div>
        </div>
      </Layout>
    );
  }
}

export default CareerPage;

export const pageQuery = graphql`
  query {
          site {
        siteMetadata {
          title
        }
        }
    allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC }) {
          edges {
            node {
              id
              html
            frontmatter {
              path
              title
              starting
              location
      }
    }
  }
}
    officeImageKoeln: file(relativePath: {eq: "offices/koeln.png" }) {
          childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
        }
      }
    officeImageMuenster: file(relativePath: {eq: "offices/muenster.jpg" }) {
          childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
        }
      }
    }
  `;
