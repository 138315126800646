import React from 'react';

import PropTypes from 'prop-types';
import ContactButton from './ContactButton';

const JoinButton = ({ label, className }) => (
  <ContactButton className={className} label={label} mail="join@hochzehn.com" />
);


JoinButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

JoinButton.defaultProps = {
  label: 'Jetzt bewerben',
  className: '',
};

export default JoinButton;
